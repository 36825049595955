import dynamicImportPolyfill from 'dynamic-import-polyfill';
import { init } from '@/app.js';

dynamicImportPolyfill.initialize({
  modulePath: '/scripts/'
});

window.app.loaded = true;

init();
