function loadGAonConsent(){
    window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};
    ga.l=+new Date;
    ga('create', 'UA-160292719-5', 'auto');
    ga('set', 'anonymizeIp', true);
    ga('send', 'pageview');
    const gascript = document.createElement("script");
    gascript.async = true;
    gascript.src = "https://www.googletagmanager.com/gtag/js?id=UA-160292719-5";
    document.getElementsByTagName("head")[0].appendChild(gascript, document.getElementsByTagName("head")[0]);
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'UA-160292719-5');
  }

  if (document.cookie.split(';').filter(function(item) {
    return item.indexOf('cookieconsent_status=allow') >= 0
  }).length) {
    loadGAonConsent();
  }

  window.addEventListener("load", function(){
    window.cookieconsent.initialise({
      "palette": {
        "popup": {
          "background": "#293189",
          "text": "#fff"
        },
        "button": {
          "background": "#fff",
          "text": "#293189"
        }
      },
      "theme": "edgeless",
      "showLink": true,
      "type": "opt-in",
      "position": "top",
      "content": {
        "policy": "Cookie-Einstellungen",
        "message": "Wir möchten Cookies verwenden, um Dir einen personalisierten und bestmöglichen Service auf unserer Website zu gewährleisten.",
        "deny":"Nein, danke",
        "allow": "Einverstanden",
        "link": "Weitere Informationen",
        "href": "https://www.dalli-group.com/de/datenschutz.html"
      },
      onStatusChange: function(status, chosenBefore) {
      let type = this.options.type;
      let didConsent = this.hasConsented();
      if (type === 'opt-in' && !didConsent) {
          // enable cookies
          loadGAonConsent();
        }
      location.reload();
      }
    });
  });