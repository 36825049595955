import * as dat from 'dat.gui'
import { light } from '@/webgl/scenes/Lights.js'

const gui = new dat.GUI()
gui.hide()

export const parameters = {
    ambientLightColor: '#d9e6ff',
    fogColor: '#0058fa',
    sceneBackgroundColor: '#0c498e',
    fogNear: 0.012,
}

gui.addColor(parameters, 'ambientLightColor')
.onChange(() =>
 {
    light.color.set(parameters.ambientLightColor)
 })

 gui.addColor(parameters, 'fogColor')
.onChange(() =>
 {
    window.app.scene.fog.color.set(parameters.fogColor)
 })

 gui.addColor(parameters, 'sceneBackgroundColor')
.onChange(() =>
{
    window.app.scene.background.set(parameters.sceneBackgroundColor)
})

