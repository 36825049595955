import Flickity from "flickity";

export default function sliderInit() {
    const elem = document.querySelector('.slider');
    const flkty = new Flickity( elem, {
      // options
      cellAlign: 'center',
      wrapAround: true,
      contain: true
    });
}
