import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';

export function createCameraFromGLTF(gltf) {
  gltf.cameras.forEach(camera => {
    const gltfcamera = camera.parent;

    window.app.scene.add(gltfcamera);
  });
}

export function switchToSectionCam(number){
  let sectionCamera = window.app.scene.getObjectByName('camera' + number).children[0]
  window.app.camera.base = sectionCamera
  window.app.camera.base.far = 140
}