import * as THREE from 'three'
import { gltfLoader, updateAllMaterials } from '../LoadingManager.js'
import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler.js'
import { createCameraFromGLTF } from '../camera.js'
import gsapInit from '@/gsap/ScrollSnapping.js'

const count = 600
const smallCount = 200
const largeCount = 800
const _position = new THREE.Vector3()
const _normal = new THREE.Vector3()

const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

const randomizeRotationMatrix = function () {

    const position = new THREE.Vector3();
    const rotation = new THREE.Euler();
    const quaternion = new THREE.Quaternion();
    const scale = new THREE.Vector3();

    return function ( matrix, positionX, positionY, positionZ ) {

        position.x = positionX;
        position.y = positionY;
        position.z = positionZ;

        //rotation.x = Math.random() * 2 * Math.PI;
        rotation.y = Math.random() * 2 * Math.PI;
        //rotation.z = Math.random() * 2 * Math.PI;

        quaternion.setFromEuler( rotation );

        scale.x = scale.y = scale.z = clamp(Math.random() * 1, 0.4, 1);

        matrix.compose( position, quaternion, scale );

    };

}();

function addRandomToGround(sampler, matrix, scene, gltf, name, count) {
    const gltfScene = gltf.scene.getObjectByName(name);
    const geometry = gltfScene.geometry.clone();
    const material = gltfScene.material;
    const mesh = new THREE.InstancedMesh( geometry, material, count);

    for ( let i = 0; i < count; i++ ) {
        sampler.sample( _position )
        randomizeRotationMatrix( matrix, _position.x, _position.y, _position.z )
        mesh.updateMatrix()
        mesh.setMatrixAt( i, matrix )
    }

    scene.add(mesh);
}

export const gltfScene = (scene) => {
    gltfLoader.load(
        'assets/models/Scene/scene.glb',
        (gltf) =>
        {
            //console.log('success')
            //console.log(gltf)

            //const water = gltf.scene.getObjectByName('water')
            //scene.add(water)

            const section1 = gltf.scene.getObjectByName('section1')
            //section1.material.opacity = .7
            //section1.material.transparent = true
            scene.add(section1)

            const icebergsBG = gltf.scene.getObjectByName('icebergs-bg')
            scene.add(icebergsBG)

            const lightrays = gltf.scene.getObjectByName('lightrays')
            lightrays.material.transparent = true
            lightrays.material.opacity = 1
            lightrays.material.depthWrite = false
            lightrays.renderOrder = 2
            scene.add(lightrays)

            const section1Deco = gltf.scene.getObjectByName('section1_deco')
            //section1Deco.material.opacity = .6
            //section1Deco.material.transparent = true
            scene.add(section1Deco)

            const bubbles = gltf.scene.getObjectByName('bubbles')
            //bubbles.material.transparent = true
            //bubbles.material.opacity = .5
            scene.add(bubbles)

            const orca = gltf.scene.getObjectByName('orca-wrapper')
            scene.add(orca)

            const section2 = gltf.scene.getObjectByName('section2')
            scene.add(section2)

            const fishSwarm = gltf.scene.getObjectByName('fishSwarm')
            scene.add(fishSwarm)

            /**
            const section5 = gltf.scene.getObjectByName('section5')
            scene.add(section5)
             */

            const section6 = gltf.scene.getObjectByName('section6')
            scene.add(section6)

            const section7 = gltf.scene.getObjectByName('section7')
            scene.add(section7)

            const matrix = new THREE.Matrix4()
            const basicGroundMesh = gltf.scene.getObjectByName('ground_converted')
            basicGroundMesh.geometry = basicGroundMesh.geometry.toNonIndexed()
            basicGroundMesh.material.flatShading = true
            const sampler = new MeshSurfaceSampler(basicGroundMesh)
            .setWeightAttribute( null )
            .build();

            scene.add(basicGroundMesh)

            const backgroundMeshNoise = gltf.scene.getObjectByName('background_noise')
            backgroundMeshNoise.material.fog = false
            scene.add(backgroundMeshNoise)

            addRandomToGround(sampler, matrix, scene, gltf, 'Stone1', count);

            addRandomToGround(sampler, matrix, scene, gltf, 'Stone2', count);

            addRandomToGround(sampler, matrix, scene, gltf, 'Plant1', smallCount);

            addRandomToGround(sampler, matrix, scene, gltf, 'Plant2', largeCount);

            addRandomToGround(sampler, matrix, scene, gltf, 'Plant3', largeCount);

            addRandomToGround(sampler, matrix, scene, gltf, 'Plant4', largeCount);

            createCameraFromGLTF(gltf)

            gsapInit()

            updateAllMaterials(scene)
        },
        (progress) =>
        {
            //console.log('progress')
            //console.log(progress)
        },
        (error) =>
        {
            console.log('error')
            console.log(error)
        }
    )
}
