import Odometer from '@/vendor/odometer.js';

const counter = document.querySelector(".counter__digits");

export const odometer = new Odometer({
  el: counter,
  auto: true,
  format: 'dd.ddd',
  value: '00000',
  duration: 5000,
});
