import odometerInit from '@/gsap/odometerIntersection'
import sliderInit from '@/modules/slider'
import * as THREE from 'three'
import '@/vendor/cookieConsent';

//import { stats } from '@/debug/stats.js'
import { parameters } from '@/debug/datGui'
import { overlay, loadingDone } from '@/webgl/LoadingManager.js'

import { gltfScene } from '@/webgl/scenes/gltfScene'
import { light } from '@/webgl/scenes/Lights.js'


window.app.camera = {}


export function init() {
    /**
     * Base
     */
    // Canvas
    const canvas = document.querySelector('canvas.webgl')

    // Scene
    const scene = new THREE.Scene()
    window.app.scene = scene
    scene.background = new THREE.Color('#0c498e')

    scene.add(overlay)
    scene.add(light)

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.9)
    directionalLight.position.set(1, 4, 1)
    //scene.add(directionalLight)

    const directionalLightHelper = new THREE.DirectionalLightHelper(directionalLight, 0.9)
    scene.add(directionalLightHelper)

    gltfScene(scene)

    scene.fog = new THREE.FogExp2(parameters.fogColor, parameters.fogNear );

    /**
     * Helpers
     */
    //const axesHelper = new THREE.AxesHelper(5);
    //scene.add(axesHelper);

    /**
     * Sizes
     */
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }

    window.addEventListener('resize', () =>
    {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight

        // Update camera
        window.app.camera.base.aspect = sizes.width / sizes.height
        window.app.camera.base.updateProjectionMatrix()

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })

    // init gsap
    odometerInit()

    // init slider
    sliderInit()

    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: false
    })
    renderer.setSize(sizes.width, sizes.height)

    //Optimize Pixel Ratio on Devices
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    window.app.renderer = renderer

    /**
     * Animate
     */

    const tick = () =>
    {
        // FPS Counter
        //stats.begin()
        //stats.end()

        if(loadingDone) {
            renderer.render(scene, window.app.camera.base)
            //window.app.composer.render(scene, window.app.camera.base)
        }

        // Call tick again on the next frame
        window.requestAnimationFrame(tick)
    }

    tick()

    import('@/vendor/cookieConsentLib')
}
