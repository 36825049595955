import { odometer } from '@/modules/odometerConfig'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

export default function odometerInit() {
    gsap.to('.counter', {
        scrollTrigger: {
            trigger: '.counter',
            start: 'top 70%',
            end: 'bottom 20%',
            onEnter: () => odometer.update(89094),
        }
    })
}
