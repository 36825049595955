import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ShaderMaterial } from 'three'

export let loadingDone = false

gsap.registerPlugin(ScrollTrigger);

//Update Materials of GLTF File
export const updateAllMaterials = (scene) =>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            child.material.needsUpdate = true
        }
    })
}

/**
 * Loaders
 */
const bodyElement = document.querySelector('body')
const loaderElement = document.querySelector('.loader')
const loaderPercent = document.querySelector('.loader p')

const loadingManager = new THREE.LoadingManager(
    // Loaded
    () => {
        gsap.delayedCall(0.5, () => {
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0})

            bodyElement.classList.add('scroll')
            loaderElement.classList.add('ended')

            loadingDone = true
        })
    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) => {
        const progressRatio = parseInt((itemsLoaded / itemsTotal) * 100)
        loaderPercent.innerHTML = progressRatio + '%'
    }
 )
 export const gltfLoader = new GLTFLoader(loadingManager)
 export const textureLoader = new THREE.TextureLoader(loadingManager)

 /**
 * Overlay
 */
const overlayGeometry = new THREE.PlaneBufferGeometry(2, 2, 1, 1)
const overlayMaterial = new ShaderMaterial({
    uniforms: {
        uAlpha: {value: 1}
    },
    vertexShader: `
        void main() {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;

        void main() {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `,
    transparent: true,
})

export const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)