import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { switchToSectionCam } from '@/webgl/camera'

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default function gsapInit() {
    // setup camera
    switchToSectionCam(0)
    const width = window.innerWidth
    const height = window.innerHeight

    // Update camera
    window.app.camera.base.aspect = width / height
    window.app.camera.base.updateProjectionMatrix()

    // desktop cameras
    const camera0 = window.app.scene.getObjectByName('camera0')
    const camera1 = window.app.scene.getObjectByName('camera1')
    const camera2 = window.app.scene.getObjectByName('camera2')
    const camera3 = window.app.scene.getObjectByName('camera3')
    const camera5 = window.app.scene.getObjectByName('camera5')
    const camera6 = window.app.scene.getObjectByName('camera6')
    const camera7 = window.app.scene.getObjectByName('camera7')
    const camera8 = window.app.scene.getObjectByName('camera8')
    const camera9 = window.app.scene.getObjectByName('camera9')

    // mobile cameras
    const camera10 = window.app.scene.getObjectByName('camera10')
    const camera11 = window.app.scene.getObjectByName('camera11')
    const camera12 = window.app.scene.getObjectByName('camera12')
    const camera13 = window.app.scene.getObjectByName('camera13')
    const camera14 = window.app.scene.getObjectByName('camera14')
    const camera15 = window.app.scene.getObjectByName('camera15')
    const camera16 = window.app.scene.getObjectByName('camera16')
    const camera17 = window.app.scene.getObjectByName('camera17')

    // 3d assets
    const icebergsBg = window.app.scene.getObjectByName('icebergs-bg')
    const section1 = window.app.scene.getObjectByName('section1')
    const orca = window.app.scene.getObjectByName('orca-wrapper')
    const orcaSingle = window.app.scene.getObjectByName('orca')
    const fishSwarm =window.app.scene.getObjectByName('fishSwarm')
    const section2 = window.app.scene.getObjectByName('section2')
    const section6 = window.app.scene.getObjectByName('section6Pingu')
    const section7 = window.app.scene.getObjectByName('section7')

    ScrollTrigger.defaults({
        immediateRender: false,
        onEnter: () => {
            ScrollTrigger.refresh()
        },
    });

    // Animate CameraPath according to window.width
    ScrollTrigger.matchMedia({

        // animate camera paths for desktop
        '(min-width: 800px)': function() {
            switchToSectionCam(0)

            // Section 1
            gsap.to(window.app.camera.base.parent.position, {
                x: camera1.position.x,
                y: camera1.position.y,
                z: camera1.position.z,

                scrollTrigger: {
                    trigger: '#intro',
                    start: 'top 70%',
                    end: 'center 20%',
                    scrub: true,
                    //markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.rotation, {
                x: camera1.rotation.x,
                y: camera1.rotation.y,
                z: camera1.rotation.z,

                scrollTrigger: {
                    trigger: '#intro',
                    start: 'top 70%',
                    end: 'center 20%',
                    scrub: true,
                    //markers: false,
                },
            })

            //Section 2
            gsap.to(window.app.camera.base.parent.position, {
                x: camera2.position.x,
                y: camera2.position.y,
                z: camera2.position.z,

                scrollTrigger: {
                    trigger: '#section1',
                    start: 'top 70%',
                    end: 'center 20%',
                    scrub: true,
                    //markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.rotation, {
                x: camera2.rotation.x,
                y: camera2.rotation.y,
                z: camera2.rotation.z,

                scrollTrigger: {
                    trigger: '#section1',
                    start: 'top 70%',
                    end: 'center 20%',
                    scrub: true,
                    //markers: false,
                },
            })

            //Section 3
            gsap.to(window.app.camera.base.parent.position, {
                x: camera3.position.x,
                y: camera3.position.y,
                z: camera3.position.z,

                scrollTrigger: {
                    trigger: '#section2',
                    start: 'top 70%',
                    end: 'center 20%',
                    scrub: true,
                    //markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.rotation, {
                x: camera3.rotation.x,
                y: camera3.rotation.y,
                z: camera3.rotation.z,

                scrollTrigger: {
                    trigger: '#section2',
                    start: 'top 70%',
                    end: 'center 20%',
                    scrub: true,
                    //markers: false,
                },
            })

            //Section 6
            gsap.to(window.app.camera.base.parent.position, {
                x: camera6.position.x,
                y: camera6.position.y,
                z: camera6.position.z,

                scrollTrigger: {
                    trigger: '#section6',
                    start: 'top 90%',
                    end: 'top 20%',
                    scrub: true,
                    //markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.rotation, {
                x: camera6.rotation.x,
                y: camera6.rotation.y,
                z: camera6.rotation.z,

                scrollTrigger: {
                    trigger: '#section6',
                    start: 'top 90%',
                    end: 'top 20%',
                    scrub: true,
                    //markers: false,
                },
            })

            //Section 7
            gsap.to(window.app.camera.base.parent.position, {
                x: camera7.position.x,
                y: camera7.position.y,
                z: camera7.position.z,

                scrollTrigger: {
                    trigger: '#section7',
                    start: 'top 90%',
                    end: 'top+=200px 20%',
                    scrub: true,
                    //markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.rotation, {
                x: camera7.rotation.x,
                y: camera7.rotation.y,
                z: camera7.rotation.z,

                scrollTrigger: {
                    trigger: '#section7',
                    start: 'top 90%',
                    end: 'top+=200px 20%',
                    scrub: true,
                    //markers: false,
                },
            })

            //Section 9
            gsap.to(window.app.camera.base.parent.position, {
                x: camera9.position.x,
                y: camera9.position.y,
                z: camera9.position.z,

                scrollTrigger: {
                    trigger: '#section8',
                    start: 'top 90%',
                    end: 'top+=200px 20%',
                    endTrigger: '#section9',
                    scrub: true,
                    //markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.rotation, {
                x: camera9.rotation.x,
                y: camera9.rotation.y,
                z: camera9.rotation.z,

                scrollTrigger: {
                    trigger: '#section8',
                    start: 'top 90%',
                    end: 'top+=200px 20%',
                    endTrigger: '#section9',
                    scrub: true,
                    //markers: false,
                },
            })
        },

        // animate camera paths for mobile
        '(max-width: 799px)': function() {
            // Section 1 mobile
            gsap.to(window.app.camera.base.parent.position, {
                x: camera10.position.x,
                y: camera10.position.y,
                z: camera10.position.z,

                scrollTrigger: {
                    trigger: '#intro',
                    start: 'top 70%',
                    end: 'center 40%',
                    scrub: true,
                    //markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.rotation, {
                x: camera10.rotation.x,
                y: camera10.rotation.y,
                z: camera10.rotation.z,

                scrollTrigger: {
                    trigger: '#intro',
                    start: 'top 70%',
                    end: 'center 40%',
                    scrub: true,
                    //markers: false,
                },
            })

            // Section 2
            gsap.to(window.app.camera.base.parent.position, {
                x: camera11.position.x,
                y: camera11.position.y,
                z: camera11.position.z,

                scrollTrigger: {
                    trigger: '#section1__content',
                    start: 'top 70%',
                    end: 'center 40%',
                    scrub: true,
                    markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.rotation, {
                x: camera11.rotation.x,
                y: camera11.rotation.y,
                z: camera11.rotation.z,

                scrollTrigger: {
                    trigger: '#section1__content',
                    start: 'top 70%',
                    end: 'center 40%',
                    scrub: true,
                    markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.position, {
                x: camera12.position.x,
                y: camera12.position.y,
                z: camera12.position.z,

                scrollTrigger: {
                    trigger: '#section1__spacing',
                    start: 'top-=100px 70%',
                    end: 'center-=100px 40%',
                    scrub: true,
                    markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.rotation, {
                x: camera12.rotation.x,
                y: camera12.rotation.y,
                z: camera12.rotation.z,

                scrollTrigger: {
                    trigger: '#section1__spacing',
                    start: 'top-=100px 70%',
                    end: 'center-=100px 40%',
                    scrub: true,
                    markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.position, {
                x: camera13.position.x,
                y: camera13.position.y,
                z: camera13.position.z,

                scrollTrigger: {
                    trigger: '#section2',
                    start: 'top 70%',
                    end: 'center 40%',
                    scrub: true,
                    markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.rotation, {
                x: camera13.rotation.x,
                y: camera13.rotation.y,
                z: camera13.rotation.z,

                scrollTrigger: {
                    trigger: '#section2',
                    start: 'top 70%',
                    end: 'center 40%',
                    scrub: true,
                    markers: false,
                },
            })

            // section 6
            gsap.to(window.app.camera.base.parent.position, {
                x: camera14.position.x,
                y: camera14.position.y,
                z: camera14.position.z,

                scrollTrigger: {
                    trigger: '#section6__spacing',
                    start: 'top-=150px 70%',
                    end: '50px 40%',
                    scrub: true,
                    markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.rotation, {
                x: camera14.rotation.x,
                y: camera14.rotation.y,
                z: camera14.rotation.z,

                scrollTrigger: {
                    trigger: '#section6__spacing',
                    start: 'top-=150px 70%',
                    end: '50px 40%',
                    scrub: true,
                    markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.position, {
                x: camera15.position.x,
                y: camera15.position.y,
                z: camera15.position.z,

                scrollTrigger: {
                    trigger: '#section6 .panel__content',
                    start: 'top 70%',
                    end: '600px 40%',
                    scrub: true,
                    markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.rotation, {
                x: camera15.rotation.x,
                y: camera15.rotation.y,
                z: camera15.rotation.z,

                scrollTrigger: {
                    trigger: '#section6 .panel__content',
                    start: 'top 70%',
                    end: '600px 40%',
                    scrub: true,
                    markers: false,
                },
            })

            // Section 7
            gsap.to(window.app.camera.base.parent.position, {
                x: camera16.position.x,
                y: camera16.position.y,
                z: camera16.position.z,

                scrollTrigger: {
                    trigger: '#section7 .panel__spacing',
                    start: 'top-=150px 70%',
                    end: '50px 40%',
                    scrub: true,
                    markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.rotation, {
                x: camera16.rotation.x,
                y: camera16.rotation.y,
                z: camera16.rotation.z,

                scrollTrigger: {
                    trigger: '#section7 .panel__spacing',
                    start: 'top-=150px 70%',
                    end: '50px 40%',
                    scrub: true,
                    markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.position, {
                x: camera17.position.x,
                y: camera17.position.y,
                z: camera17.position.z,

                scrollTrigger: {
                    trigger: '#section7 .panel__content',
                    start: 'top 70%',
                    end: '600px 40%',
                    scrub: true,
                    markers: false,
                },
            })

            gsap.to(window.app.camera.base.parent.rotation, {
                x: camera17.rotation.x,
                y: camera17.rotation.y,
                z: camera17.rotation.z,

                scrollTrigger: {
                    trigger: '#section7 .panel__content',
                    start: 'top 70%',
                    end: '600px 40%',
                    scrub: true,
                    markers: false,
                },
            })
        }
    })

    // Loop Animations
    // todo: only animate when in viewport
    //Section 1
    gsap.to(orca.rotation, {
        y: 1.2,

        scrollTrigger: {
            trigger: '#intro',
            start: 'top 70%',
            end: 'center 20%',
            scrub: true,
            //markers: false,
        },
    })

    gsap.to(orcaSingle.position, {
        y: '+=0.1',
        duration: 3,
        delay: 1,
        yoyo: true,
        ease: 'Power1.easeInOut',
        yoyoEase: true,
        repeat: -1
    })

    gsap.to(section1.position, {
        y: '+=0.06',
        duration: 2,
        delay: 1.5,
        yoyo: true,
        ease: 'Power1.easeInOut',
        yoyoEase: true,
        repeat: -1
    })

    gsap.to(icebergsBg.position, {
        y: '+=0.1',
        duration: 2,
        delay: 0.5,
        yoyo: true,
        ease: 'Power1.easeInOut',
        yoyoEase: true,
        repeat: -1
    })

    //Section 2
    gsap.to(fishSwarm.rotation, {
        y: 0.005,

        scrollTrigger: {
            trigger: '#section1__spacing',
            start: 'top 70%',
            end: 'center 20%',
            scrub: true,
            //markers: false,
        },
    })

    gsap.to(fishSwarm.position, {
        y: '-=1',

        scrollTrigger: {
            trigger: '#section1__spacing',
            start: 'top 70%',
            end: 'center 20%',
            scrub: true,
            //markers: false,
        },
    })

    gsap.to(section2.position, {
        y: '+=0.1',
        duration: 2,
        yoyo: true,
        ease: 'Power1.easeInOut',
        yoyoEase: true,
        repeat: -1
    })

    gsap.to(section2.rotation, {
        y: '-=0.05',
        x: '+=0.04',
        duration: 2,
        yoyo: true,
        ease: 'Power1.easeInOut',
        yoyoEase: true,
        repeat: -1
    })

    // section 6
    gsap.to(section6.position, {
        y: '+=0.1',
        duration: 2,
        yoyo: true,
        ease: 'Power1.easeInOut',
        yoyoEase: true,
        repeat: -1
    })

    gsap.to(section6.rotation, {
        y: '-=0.04',
        x: '+=0.04',
        duration: 2,
        yoyo: true,
        ease: 'Power1.easeInOut',
        yoyoEase: true,
        repeat: -1
    })

    // section 7
    gsap.to(section7.position, {
        y: '+=0.1',
        duration: 2,
        yoyo: true,
        ease: 'Power1.easeInOut',
        yoyoEase: true,
        repeat: -1
    })

    gsap.to(section7.rotation, {
        y: '-=0.05',
        x: '+=0.04',
        duration: 2,
        yoyo: true,
        ease: 'Power1.easeInOut',
        yoyoEase: true,
        repeat: -1
    })

    // scroll to content
    const scrollButton = document.getElementsByClassName('scroll-cta')
    scrollButton[0].addEventListener('click', (e) => {
        gsap.to(window, {duration: 3, scrollTo:"#section1"});
    })
}

window.addEventListener("DOMContentLoaded", function() {
    ScrollTrigger.refresh()
});
